import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import NotFound from "../scenes/NotFound/NotFound";

export default function NotFoundPage(props) {
  return (
    <Layout navbarTheme="none" data={props.data.contentfulMenuLabel}>
      <NotFound value={props.data.contentful404} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentful404(node_locale: { eq: $locale }) {
      catchPhrase
      cta
      lost
      node_locale
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      accept
      decline
      cookie
      knowMore
    }
  }
`;
