// extracted by mini-css-extract-plugin
export var button = "NotFound-module--button--44555";
export var compass = "NotFound-module--compass--5eb00";
export var compassContainer = "NotFound-module--compassContainer--71af7";
export var container = "NotFound-module--container--960fa";
export var image = "NotFound-module--image--56a4e";
export var overlay = "NotFound-module--overlay--7c9e8";
export var root = "NotFound-module--root--7b3c1";
export var spin = "NotFound-module--spin--ce0a3";
export var text = "NotFound-module--text--7c459";
export var title = "NotFound-module--title--51095";
export var zenityLogo = "NotFound-module--zenityLogo--5a52b";