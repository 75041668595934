import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { BorderButton } from "../../components/Button/Button";
import { SlideTransition } from "../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../helpers/localeLink";
import zenityWhiteLogo from "../../resources/logos/zenity-white.svg";
import * as styles from "./NotFound.module.css";

export default function NotFound({ value }) {
  return (
    <>
      <div className={styles.compassContainer}>
        <StaticImage
          src="../../resources/images/footer/fleur-du-vent.png"
          alt="fleur"
          width={250}
          className={styles.compass}
        />
      </div>
      <div className={styles.root}>
        <div className={styles.zenityLogo}>
          <Link to={localeLink("/")}>
            <img src={zenityWhiteLogo} alt="logo" />
          </Link>
        </div>
        <div className={styles.container}>
          <SlideTransition stythresholds={0} delay={0.4}>
            <div className={styles.title}>{value.lost}</div>
            <div className={styles.text}>{value.catchPhrase}</div>
          </SlideTransition>
          <SlideTransition thresholds={0} delay={0.8}>
            <div className={styles.button}>
              <Link to={localeLink("/")}>
                <BorderButton label={value.cta} color="#fff" wide={true} />
              </Link>
            </div>
          </SlideTransition>
        </div>
        <div className={styles.overlay} />
      </div>
    </>
  );
}
